import React, { useState } from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';

import { Typography } from '@deeptrue-frontend/ui';
import { ReactComponent as IconLeft } from '../../../assets/icons/svg/arrow_left_white.svg';
import { ReactComponent as IconRight } from '../../../assets/icons/svg/arrow_right_white.svg';

const Section = styled.section`
  width: 100%;
  background-color: #001f33;
  display: flex;
  flex-direction: column;

  padding: 3.875rem 1.5rem;

  @media (min-width: 75rem) {
    padding: 3.875rem 14.68rem;
  }
`;

const SectionTitle = styled(Typography)`
  font-weight: 700;
  font-size: 1rem;
  color: #759ef0;
  margin: 1rem 0;
`;

const Video = styled.iframe`
  aspect-ratio: 16 / 9;
  width: 100%;
`;

const MainVideoTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  color: white;
  margin: 0.5rem 0;
`;

const VideoWrapper = styled.div`
  margin-top: 0.5rem;
`;

const OtherVideosWrapper = styled.div`
  width: 100%;
  margin: 1.375rem 0;
  position: relative;

  @media (min-width: 75rem) {
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .react-multi-carousel-list {
    padding-bottom: 1.5rem;
  }
  .react-multi-carousel-item {
    padding: 0 1.25rem 0 0;
    @media (min-width: 75rem) {
      padding: 0 2.56rem;
    }
  }

  .react-multi-carousel-track {
    margin: 0 auto;
  }

  .react-multi-carousel-dot {
    button {
      background-color: #a4aecf;
      border: none;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 3px;
    }
  }

  .react-multi-carousel-dot--active {
    button {
      background-color: #1f5dea;
      border: none;
      width: 1.16rem;
      height: 0.5rem;
      border-radius: 3px;
    }
  }
`;

const SmallVideoWrapper = styled.div`
  cursor: pointer;
`;

const OtherVideosTitle = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  color: white;
  margin: 0.5rem 0;
  align-self: flex-start;
`;

const VideoMiniature = styled.iframe`
  aspect-ratio: 16 / 9;
  width: 100%;
  pointer-events: none;
`;

const SmallVideoTitle = styled(Typography)`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: white;
  margin: 0.5rem 0;
  @media (min-width: 75rem) {
    margin: 1.5rem 0 2.5rem 0;
  }
`;
const CarouselWrapper = styled.div`
  @media (min-width: 75rem) {
    width: calc(100% + 5.125rem);
  }
`;
const Button = styled.span`
  background-color: transparent;
  cursor: pointer;
  &:hover {
    svg > path {
      fill: #d1dffa;
    }
  }
`;

const ButtonGroupWrapper = styled.div`
  display: none;
  @media (min-width: 75rem) {
    position: absolute;
    top: 4rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

interface Props {
  title: string;
  videosData: {
    primary: {
      video_title: string;
      embed_link: string;
      other_videos_label: string;
    };
    items: {
      video_title: string;
      embed_link: string;
    }[];
  };
}

const VideoSection = ({ title, videosData }: Props) => {
  const [videos, setVideos] = useState({
    main: {
      video_title: videosData.primary.video_title,
      embed_link: videosData.primary.embed_link,
    },
    other: videosData.items,
  });

  const changeVideo = (video) => {
    const otherVideos = videos.other.filter(
      (el) => el.embed_link !== video.embed_link
    );

    otherVideos.push(videos.main);
    setVideos({
      main: video,
      other: otherVideos,
    });
  };

  const CustomButtonGroup = ({ next, previous, ...rest }: any) => {
    const {
      carouselState: { currentSlide },
    } = rest;

    return (
      <ButtonGroupWrapper>
        <Button
          className={currentSlide === 0 ? 'disable' : ''}
          onClick={() => previous()}
        >
          <IconLeft />
        </Button>
        <Button onClick={() => next()}>
          <IconRight />
        </Button>
      </ButtonGroupWrapper>
    );
  };

  return (
    <Section>
      <SectionTitle>{title}</SectionTitle>
      <MainVideoTitle>{videos.main.video_title}</MainVideoTitle>
      <VideoWrapper>
        <Video
          src={videos.main.embed_link}
          title={title}
          allow="accelerometer; autoplay; encrypted-media; gyroscope;"
          allowFullScreen={false}
          frameBorder="0"
        />
      </VideoWrapper>

      {videos.other.length > 0 && (
        <OtherVideosWrapper>
          <OtherVideosTitle>
            {videosData.primary.other_videos_label}
          </OtherVideosTitle>
          <CarouselWrapper>
            <Carousel
              showDots
              partialVisible
              infinite
              centerMode={false}
              arrows={false}
              customButtonGroup={<CustomButtonGroup />}
              removeArrowOnDeviceType={['mobile', 'small']}
              responsive={{
                desktop: {
                  breakpoint: { max: 8000, min: 1200 },
                  items: 3,
                },
                tablet: {
                  breakpoint: { min: 960, max: 1200 },
                  items: 3,
                },
                mobile: {
                  breakpoint: { max: 960, min: 700 },
                  items: 2,
                },
                small: {
                  breakpoint: { max: 700, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 60,
                },
                x_small: {
                  breakpoint: { max: 480, min: 0 },
                  items: 1,
                  partialVisibilityGutter: 60,
                },
              }}
            >
              {videos.other.map((video, idx) => {
                return (
                  <div key={idx}>
                    <SmallVideoWrapper
                      onClick={() => {
                        changeVideo(video);
                      }}
                    >
                      <VideoMiniature
                        src={video.embed_link}
                        title={video.video_title}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                        allowFullScreen
                        frameBorder="0"
                      />
                    </SmallVideoWrapper>
                    <SmallVideoTitle>{video.video_title}</SmallVideoTitle>
                  </div>
                );
              })}
            </Carousel>
          </CarouselWrapper>
        </OtherVideosWrapper>
      )}
    </Section>
  );
};

export default VideoSection;
