/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import styled from 'styled-components';
import {
  PricingContext,
  currencySymbols,
  getCurrencyName,
} from '../../../providers/PricingProvider';
import { Link } from 'gatsby';
import { Typography, Button } from '@deeptrue-frontend/ui';
import Optional from '../../../assets/icons/optional.svg';

const Plan = styled.div`
  border: 2px solid #e3e5e8;
  background-color: white;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 46rem;
  padding: 34px 27px;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

const Line = styled.div`
  display: none;
  width: 1px;
  background-color: #e3e5e8;
  margin: 0 2.5rem;
  @media (min-width: 800px) {
    display: block;
  }
`;

const LineHorizontal = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e5e8;
  margin: 1.75rem 0 1.25rem 0;
  @media (min-width: 800px) {
    display: none;
  }
`;

const Feature = styled.div`
  display: flex;
`;

const DescriptionWrapper = styled.div`
  @media (min-width: 800px) {
    width: 23rem;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 800px) {
    width: 23rem;
  }
`;

const StyledTypography = styled(Typography)`
  margin-top: 2.5rem;
  @media (min-width: 800px) {
    font-size: 3rem;
    line-height: 4rem;
  }
`;

const CreditsTypography = styled(Typography)`
  margin-top: 1rem;
  @media (min-width: 800px) {
    margin-top: 0;
    font-size: 1rem;
    font-weight: 500;
  }
`;

interface Props {
  title: string;
  subTitle: string;
  price: string;
  data: string;
  priceDesc?: string;
  dataDesc: any;
  featureTitle: string;
  features: Array<{
    plan_default_feature: string;
    is_optional_metric: boolean;
  }>;
  ctaText: string;
  toolDjango: string;
  areOptionalMetricsVisible?: boolean;
}

const PricingPlan = ({
  title,
  subTitle,
  data,
  featureTitle,
  features,
  ctaText,
  toolDjango,
  areOptionalMetricsVisible,
}: Props) => {
  return (
    <PricingContext.Consumer>
      {({ pricingData, toolsPricing }) => {
        return (
          <Plan>
            <MainWrapper>
              <>
                <Typography
                  weight="medium"
                  variant="copy1"
                  fontSize="2rem"
                  lineHeight="3rem"
                >
                  {title}
                </Typography>
                <Typography
                  fontSize="0.875rem"
                  weight="medium"
                  variant="copy1"
                  color="#676E7C"
                >
                  {subTitle}
                </Typography>

                <StyledTypography
                  fontSize="2.5rem"
                  weight="medium"
                  variant="copy1"
                  color="#253858"
                >
                  {Math.round(
                    toolsPricing[toolDjango].price *
                      pricingData.currencyRates[
                        getCurrencyName(pricingData.currentCurrency)
                      ]
                  )}{' '}
                  {
                    currencySymbols[
                      getCurrencyName(pricingData.currentCurrency)
                    ]
                  }
                </StyledTypography>

                <CreditsTypography
                  fontSize="1rem"
                  lineHeight="1.5rem"
                  variant="copy1"
                  color="#253858"
                  weight="regular"
                  mt="0"
                >
                  {'('}
                  {toolsPricing[toolDjango].price}
                  {' Credits)'}
                </CreditsTypography>
                <Typography
                  fontSize="1rem"
                  variant="copy1"
                  color="#737C8C"
                  weight="medium"
                  mt="1.2rem"
                >
                  {data}
                </Typography>

                {ctaText && (
                  <Link
                    to={`/brief?briefing=${toolDjango}`}
                    style={{ width: '100%' }}
                  >
                    <Button style={{ width: '100%' }} mt="2.5rem" mb="2rem">
                      {ctaText}
                    </Button>
                  </Link>
                )}
              </>
            </MainWrapper>
            <Line />
            <LineHorizontal />
            <DescriptionWrapper>
              <Typography
                fontSize="1rem"
                variant="copy1"
                color="#253858"
                mb="10px"
                weight="medium"
              >
                {featureTitle}
              </Typography>
              {features.map(
                (
                  {
                    plan_default_feature,
                    is_optional_metric,
                  }: {
                    plan_default_feature: string;
                    is_optional_metric: boolean;
                  },
                  index: number
                ) => {
                  return (
                    !is_optional_metric && (
                      <Feature key={index}>
                        <svg
                          className={`pricing-card__icon pricing-card__icon--success`}
                        >
                          <use xlinkHref="#check" />
                        </svg>

                        <Typography
                          ml="0.5rem"
                          variant="copy1"
                          color="#5C6370"
                          weight="regular"
                        >
                          {plan_default_feature}
                        </Typography>
                      </Feature>
                    )
                  );
                }
              )}
              {areOptionalMetricsVisible && (
                <Typography
                  fontSize="1rem"
                  variant="copy1"
                  color="#253858"
                  mb="10px"
                  mt="1.5rem"
                  weight="medium"
                >
                  {'Optional'}
                </Typography>
              )}
              {features.map(
                (
                  {
                    plan_default_feature,
                    is_optional_metric,
                  }: {
                    plan_default_feature: string;
                    is_optional_metric: boolean;
                  },
                  index: number
                ) => {
                  return (
                    is_optional_metric && (
                      <Feature key={index}>
                        <img src={Optional} />

                        <Typography
                          ml="0.5rem"
                          variant="copy1"
                          color="#5C6370"
                          weight="regular"
                        >
                          {plan_default_feature}
                        </Typography>
                      </Feature>
                    )
                  );
                }
              )}
            </DescriptionWrapper>
          </Plan>
        );
      }}
    </PricingContext.Consumer>
  );
};

export default PricingPlan;
