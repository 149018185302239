import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import Title from '../Base/Title';
import Section from '../Base/Section';
import QuestionnaireCard from '../../questionnaireCard';
import iconLeft from '../../../assets/icons/iconLeft.svg';
import iconRight from '../../../assets/icons/iconRight.svg';

const Content = styled.div`
  width: 100%;
  margin: 2.5rem auto;
  position: relative;

  .react-multi-carousel-list {
    padding-bottom: 1.5rem;
  }
  .react-multi-carousel-item {
    padding: 0 20px;
  }

  .react-multi-carousel-track {
    margin: 0 auto;
  }
`;

const ArrowLeft = styled.span`
  position: absolute;
  cursor: pointer;
  top: 45%;
  left: 5%;
  transform: translateY(-50%);

  @media (min-width: 601px) {
    left: -1rem;
  }
`;

const ArrowRight = styled.span`
  position: absolute;
  right: 5%;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;

  @media (min-width: 601px) {
    right: -1rem;
  }
`;

type SlideType = {
  slide_description: string;
  slide_title: string;
};

interface Props {
  tag: string;
  title: string;
  slides: SlideType[];
}

const CustomArrows = ({ carouselState, next, previous }: any) => {
  const { currentSlide, totalItems, slidesToShow } = carouselState;

  const isPrevVisible = currentSlide !== 0;
  const isNextVisible = currentSlide + slidesToShow < totalItems;
  return (
    <>
      {isPrevVisible && (
        <ArrowLeft onClick={previous}>
          <img src={iconLeft} alt="left" />
        </ArrowLeft>
      )}
      {isNextVisible && (
        <ArrowRight onClick={next}>
          <img src={iconRight} alt="left" />
        </ArrowRight>
      )}
    </>
  );
};

const QuestionnaireFlow = ({ tag, title, slides }: Props) => {
  return (
    <Section alignItems="center">
      <Title noLine tag={tag} title={title} alignItems="center" />
      <Content className="m-carousel">
        <Carousel
          showDots={true}
          arrows={false}
          renderButtonGroupOutside
          customButtonGroup={<CustomArrows />}
          responsive={{
            desktop: {
              breakpoint: { max: 8000, min: 1201 },
              items: 4,
            },
            tablet: {
              breakpoint: { max: 1200, min: 881 },
              items: 3,
            },
            mobile: {
              breakpoint: { max: 880, min: 601 },
              items: 2,
            },
            small: {
              breakpoint: { max: 600, min: 0 },
              items: 1,
            },
          }}
        >
          {slides &&
            slides.map(({ slide_description, slide_title }, index) => {
              return (
                <QuestionnaireCard
                  key={index}
                  index={index + 1}
                  title={slide_title}
                  description={slide_description}
                />
              );
            })}
        </Carousel>
      </Content>
    </Section>
  );
};

export default QuestionnaireFlow;
