import React from 'react';
import styled from 'styled-components/macro';
import { Typography } from '@deeptrue-frontend/ui';

type CountriesModalProps = {
  title: string;
  subtitle: string;
  countries: string;
  languages: string;
  countriesList: string;
  languagesList: string;
};
const Title = styled(Typography)`
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  color: #253858;
`;
const Subtitle = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: #253858;
`;
const InjectedParagraph = styled(Typography)`
  ul {
    list-style-type: disc;
    color: #253858;
    margin-left: 1.5rem;
  }
`;
const TwoColumns = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 800px) {
    flex-direction: row;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e5e8;
  margin: 1.75rem 0 1.25rem 0;
`;
const Wrapper = styled.div`
  margin: 0.8rem;
`;
const Headline = styled.div`
  color: #253858;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  @media (min-width: 800px) {
    margin-top: 0;
  }
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 18rem;
`;
const CountriesModal = ({
  title,
  subtitle,
  countries,
  languages,
  countriesList,
  languagesList,
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Line />
      <TwoColumns>
        <Column>
          <Headline>{countries}</Headline>
          <InjectedParagraph
            dangerouslySetInnerHTML={{ __html: countriesList }}
          />
        </Column>
        <Column>
          <Headline>{languages}</Headline>

          <InjectedParagraph
            dangerouslySetInnerHTML={{ __html: languagesList }}
          />
        </Column>
      </TwoColumns>
    </Wrapper>
  );
};

export default CountriesModal;
