import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import styled from 'styled-components';
import Carousel from 'react-multi-carousel';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Step from '../components/step';
import Section from '../components/section';
import Accordion from '../components/accordion';
import PriceCard from '../components/priceCard';
import TwoColumnSection from '../components/twoColumnSection';
import CountriesModal from '../components/countriesModal';
import { NotFound } from '../utils/NotFound';
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbAnchor,
} from '../components/breadcrumb';
import KeyMetricsSection from '../components/sections/ToolsSection/KeyMetricsSection';
import DescriptionSection from '../components/sections/ToolsSection/Description';
import PricingPlanDefault from '../components/sections/Base/PricingPlanDefault';
import SectionWrapper from '../components/sections/Base/Section';
import QuestionnaireFlowSection from '../components/sections/ToolsSection/QuestionnaireFlow';
import TryOutSection from '../components/sections/ToolsSection/TryOutSection';
import WantMoreSection from '../components/sections/ToolsSection/WantMoreSection';
import KeyBenefitsSection from '../components/sections/ToolsSection/KeyBenefitsSection';
import ShowcaseSection from '../components/sections/ToolsSection/ShowcaseSection';
import TagIcon from '../assets/icons/TagIcon.svg';
import {
  PricingContext,
  currencySymbols,
  getCurrencyName,
} from '../providers/PricingProvider';
import { Typography } from '@deeptrue-frontend/ui';
import { Icon, Modal } from '@deeptrue-frontend/ui';
import fbPixel from '../utils/fbPixel';
import VideoSection from '../components/sections/ToolsSection/VideoSection';

type ProductsTemplate = {
  pageContext: {
    data: any;
    language: string;
    workbench_name: string;
    workbench_slug: string;
    workbench_icon: { url: string };
  };
  data: any;
};

const StyledIcon = styled.img`
  width: 36px;
  margin-right: 1rem;
  position: relative;
  left: -1.5rem;
  @media (max-width: 600px) {
    left: 0;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  a {
    width: auto;
  }
  h1 {
    position: relative;
    left: -1.5rem;
    margin-bottom: 0;
    align-items: center;
    display: flex;
  }
  @media (max-width: 960px) {
    a {
      width: auto;
    }
  }
  @media (max-width: 600px) {
    h1 {
      display: flex;
      align-items: flex-start;
    }
  }
`;

const ColouredSection = styled(Section)`
  background-color: ${(props) => props.theme.bgColor};
  margin: 0 !important;
  padding-top: 2rem;
  padding-bottom: 6rem;
  .hero__description {
    margin-top: 0;
  }
	@media (max-width: 960px) {
		padding: 6rem 1.5rem;
	.hero__description {
		margin-top: 0.5rem;
		line-height: 1.5rem;
	}
  @media (max-width: 600px) {
    padding: 6rem 1.5rem;
    .hero__description {
      margin-top: 0.5rem;
      line-height: 1.5rem;
    }
  }
`;

const ColouredBreadcrumb = styled.div`
  background-color: ${(props) => props.theme.bgColor};
  margin-bottom: 0 !important;
  margin-top: 0;
`;

const StyledLink = styled(Link)`
  color: #1f5dea !important;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  svg {
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
    transform: rotate(180deg);
  }
`;

const LightBlueSection = styled(Section)`
  background-color: #f6f9fe;
  padding-top: 3.625rem;
`;
const LightBlueSectionWrapper = styled(SectionWrapper)`
  background-color: #f6f9fe !important;
`;
const StyledModal = styled(Modal)`
  max-width: 40rem;
  max-height: 40rem;
  overflow-y: auto;
  .modal-header {
    margin-bottom: 0;
    height: 0;
  }
`;

const ProductsTemplate = ({ pageContext, data }: ProductsTemplate) => {
  const { allPrismicToolDetail } = data;
  const {
    workbench_name: workbenchName,
    workbench_slug: workbenchSlug,
    workbench_icon: { url: workbenchIcon },
  } = pageContext;

  const { language } = pageContext;
  const commonData = data.allPrismicCommonInformation.edges[0].node.data;

  const isProductExist = allPrismicToolDetail.edges.length > 0;

  if (!isProductExist) {
    return NotFound();
  }

  const productPrismic = allPrismicToolDetail.edges[0].node.data;

  const intl = useIntl();

  const {
    is_subscribable: isSubscribable,
    title,
    tool_django_slug,
    sections,
    is_metrics,
    metrics_pills,
    metrics_title,
    metrics_subtitle,
    description_tag,
    description_title,
    is_description,
    description_main_text,
    description_paragraphs,
    is_questionnaire_flow_section,
    questionnaire_flow_section_slides,
    questionnaire_flow_section_tag,
    questionnaire_flow_section_title,

    faq_headline,
    faq_description,
    faq_body,

    how_it_works_title,
    how_it_works_description,
    how_it_works_visible,
    prod_first_how_description,
    prod_first_how_title,
    prod_how_description,
    prod_how_first_tagline,
    prod_how_title,
    prod_second_how_description,
    prod_second_how_tagline,
    prod_second_how_title,
    prod_third_how_description,
    prod_third_how_tagline,
    prod_third_how_title,

    is_try_out_section,
    try_out_section_cta,

    is_want_more_section,
    want_more_section_links,
    want_more_section_subtitle,
    want_more_section_title,

    is_key_benefits_section,
    key_benefits_section_group,
    key_benefits_section_tag,
    key_benefits_section_title,

    countries_title,
    countries_subtitle1,
    countries_count,
    countries_and_in_text,
    countries_languages_count,
    countries_modal_title,
    countries_modal_subtitle,
    countries_text,
    languages_text,
    countries_list,
    languages_list,

    is_showcase_section_visible,
    showcase_title,
    showcase_subtitle,
    showcase_images,
  } = productPrismic;

  const isSectionsAdded =
    sections[0] && sections[0].description && sections[0].title1;
  const [isCountriesModalOpen, setCountriesModalOpen] = useState(false);

  const colors = {
    experience: '#FFF8F5',
    branding: '#F9F6FE',
    innovation: '#F8FCF8',
  };

  const videoComponentData = {
    sectionTitle: productPrismic.section_title.text,
    videosData: productPrismic.body1[0],
  };
  const isVideoSection =
    productPrismic.is_video_section_visible && !!videoComponentData.videosData;

  const theme = {
    bgColor: colors[workbenchSlug],
  };

  return (
    <PricingContext.Consumer>
      {({ pricingData, toolsPricing }) => {
        if (!toolsPricing) return null;

        const toolPrice = Math.round(
          toolsPricing[tool_django_slug].price *
            pricingData.currencyRates[
              getCurrencyName(pricingData.currentCurrency)
            ]
        );
        return (
          <Layout lang={language}>
            <Seo title="Product" lang={language} />
            <ColouredBreadcrumb className="hero hero--product" theme={theme}>
              <Breadcrumb className="breadcrumb--workbench">
                <BreadcrumbAnchor
                  label={intl.formatMessage({
                    id: 'home',
                    defaultMessage: 'home',
                  })}
                  url="/"
                />
                <BreadcrumbDivider />
                <BreadcrumbAnchor
                  label={workbenchName}
                  url={`/workbenchs/${workbenchSlug}`}
                />
                <BreadcrumbDivider />
                <BreadcrumbAnchor active label={title} />
              </Breadcrumb>
            </ColouredBreadcrumb>
            <ColouredSection className="hero hero--product" theme={theme}>
              <div className="hero__wrap">
                <div className="hero__content">
                  <StyledHeader>
                    <h1 className="hero__title hero__title--centered">
                      <StyledIcon src={workbenchIcon} alt={title} />
                      {productPrismic.headline}
                    </h1>
                  </StyledHeader>
                  <p
                    className="hero__description"
                    dangerouslySetInnerHTML={{
                      __html: productPrismic.description.html,
                    }}
                  />
                  {tool_django_slug !== 'idea-test' && (
                    <p
                      style={{
                        color: '#253858',
                        fontWeight: 500,
                        fontSize: '20px',
                      }}
                    >
                      {commonData.pricing_starting_at_text}
                      {'  '}
                      {toolsPricing[tool_django_slug].is_free && (
                        <span
                          style={{
                            textDecoration: 'line-through',
                            color: '#5C6370',
                          }}
                        >
                          {toolPrice}
                        </span>
                      )}
                      {toolsPricing[tool_django_slug].is_free && (
                        <span>{'  0 '}</span>
                      )}
                      {!toolsPricing[tool_django_slug].is_free && (
                        <span>{toolPrice}</span>
                      )}{' '}
                      {
                        currencySymbols[
                          getCurrencyName(pricingData.currentCurrency)
                        ]
                      }
                      {toolsPricing[tool_django_slug].is_free &&
                        isSubscribable && (
                          <span> {commonData.pricing_monthly_text}</span>
                        )}
                      {toolsPricing[tool_django_slug].is_free && (
                        <img src={TagIcon} />
                      )}
                    </p>
                  )}
                  <Link
                    className="hero__button"
                    to={`/brief?briefing=${tool_django_slug}`}
                    onClick={() =>
                      fbPixel('Briefing started', { tool: title, language })
                    }
                  >
                    {commonData.hiw_cta_label}
                  </Link>
                </div>
              </div>
            </ColouredSection>
            {is_description && (
              <DescriptionSection
                tag={description_tag}
                title={description_title}
                mainDescription={description_main_text}
                descriptions={description_paragraphs}
              />
            )}
            {isVideoSection && (
              <VideoSection
                title={videoComponentData.sectionTitle}
                videosData={videoComponentData.videosData}
              />
            )}
            {is_key_benefits_section && (
              <KeyBenefitsSection
                tag={key_benefits_section_tag || ''}
                title={key_benefits_section_title || ''}
                groups={key_benefits_section_group || []}
              />
            )}
            {how_it_works_visible && (
              <Section
                title={prod_how_title}
                description={prod_how_description}
                className="how"
              >
                <Step
                  className="how__step"
                  name={prod_how_first_tagline}
                  title={prod_first_how_title}
                  counter={'1'}
                >
                  <Typography>{prod_first_how_description}</Typography>
                </Step>
                <Step
                  className="how__step"
                  name={prod_second_how_tagline}
                  title={prod_second_how_title}
                  counter={'2'}
                >
                  <Typography>{prod_second_how_description}</Typography>
                </Step>
                <Step
                  className="how__step"
                  name={prod_third_how_tagline}
                  title={prod_third_how_title}
                  counter={'3'}
                >
                  <Typography>{prod_third_how_description}</Typography>
                </Step>
              </Section>
            )}
            {is_metrics && (
              <KeyMetricsSection
                metrics_pills={metrics_pills}
                metrics_title={metrics_title}
                metrics_subtitle={metrics_subtitle}
              />
            )}
            {isSectionsAdded && (
              <Section
                title={how_it_works_title}
                description={how_it_works_description}
                className="tool-page"
              >
                {productPrismic.sections &&
                  productPrismic.sections.map((section, idx) => (
                    <TwoColumnSection
                      key={`tools-feature-${idx}`}
                      title={section.title1}
                      headline={section.headline1}
                      description={`<p>${section.description}</p>`}
                      cover={section.imagen.url}
                      toggleDirection={(idx + 1) % 2 === 0}
                      withBackground={(idx + 1) % 2 === 0}
                      cta_label={section.cta_label || commonData.hiw_cta_label}
                      cta_url={`/brief?briefing=${tool_django_slug}`}
                    />
                  ))}
              </Section>
            )}
            {is_questionnaire_flow_section && (
              <QuestionnaireFlowSection
                tag={questionnaire_flow_section_tag || ''}
                title={questionnaire_flow_section_title || ''}
                slides={questionnaire_flow_section_slides || []}
              />
            )}
            {is_showcase_section_visible && (
              <ShowcaseSection
                title={showcase_title}
                subtitle={showcase_subtitle}
                images={showcase_images}
              />
            )}
            {isSubscribable ? (
              <Section
                className="tool-page__pricing pricing__cards"
                tagline={productPrismic.pricing_title}
                title={productPrismic.pricing_headline}
                description={productPrismic.pricing_description}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledLink to="/pricing">
                    {commonData.pricing_cta_text}
                    <Icon variant="arrow" />
                  </StyledLink>
                </div>
                <div className="m-carousel">
                  <Carousel
                    showDots={true}
                    responsive={{
                      desktop: {
                        breakpoint: { max: 8000, min: 1200 },
                        items: 3,
                      },
                      tablet: {
                        breakpoint: { min: 960, max: 1200 },
                        items: 3,
                      },
                      mobile: {
                        breakpoint: { max: 960, min: 700 },
                        items: 2,
                      },
                      small: {
                        breakpoint: { max: 700, min: 0 },
                        items: 1,
                      },
                      x_small: {
                        breakpoint: { max: 480, min: 0 },
                        items: 1,
                      },
                    }}
                  >
                    {productPrismic.body.map((plan: any, idx: number) => {
                      return (
                        <PriceCard
                          key={`tools-price-plan-${idx}`}
                          name={plan.primary.plan_name}
                          description={plan.primary.plan_description}
                          period={commonData.pricing_monthly_text}
                          price={plan.primary.plan_cost}
                          priceAfterDiscount={plan.primary.price_after_discount}
                          cta_text={plan.primary.pricing_cta}
                          cta_url={`/brief?briefing=${tool_django_slug}`}
                          isFeatured={plan.primary.is_featured}
                          features={[
                            ...plan.items.map((item: any) => ({
                              content: item.plan_feature_name,
                              available: item.is_available,
                              isOptionalMetric: item.is_optional_metric,
                            })),
                          ]}
                          areOptionalMetricsVisible={
                            plan.primary.optional_metrics_visible
                          }
                        />
                      );
                    })}
                  </Carousel>
                </div>
              </Section>
            ) : (
              <LightBlueSection
                className="tool-page__pricing pricing__cards"
                tagline={productPrismic.pricing_title}
                title={productPrismic.pricing_headline}
                description={productPrismic.pricing_description}
              >
                <LightBlueSectionWrapper alignItems="center">
                  <StyledLink to={`/pricing`}>
                    {commonData.pricing_cta_text}
                    <Icon variant="arrow" />
                  </StyledLink>
                  {productPrismic.body.map((plan: any, idx: number) => {
                    const { primary } = plan;
                    const {
                      plan_default_datafee_details,
                      plan_default_datafee_details_text,
                      plan_default_description,
                      plan_default_includes,
                      plan_default_price,
                      plan_default_price_data,
                      plan_default_title,
                      plan_default_vat_info,
                      plan_default_cta,
                      default_optional_metrics_visible,
                    } = primary;
                    return (
                      <>
                        <PricingPlanDefault
                          title={plan_default_title}
                          subTitle={plan_default_description}
                          price={plan_default_price}
                          data={plan_default_price_data}
                          priceDesc={plan_default_vat_info}
                          dataDesc={plan_default_datafee_details}
                          featureTitle={plan_default_includes}
                          features={productPrismic.body[0].items}
                          ctaText={plan_default_cta}
                          key={idx}
                          toolDjango={tool_django_slug}
                          areOptionalMetricsVisible={
                            default_optional_metrics_visible
                          }
                        />
                        <Typography
                          mt={'0.5rem'}
                          fontSize={'0.875rem'}
                          textAlign={'center'}
                          color={'#737C8C'}
                        >
                          {plan_default_datafee_details_text}
                        </Typography>
                      </>
                    );
                  })}
                </LightBlueSectionWrapper>
              </LightBlueSection>
            )}
            {faq_body.length > 0 && (
              <Section
                className="tool-page__faq dt-faq__list"
                title={faq_headline}
                description={faq_description.html}
              >
                <div className="dt-faq__group">
                  <h3 className="dt-faq__group-title">
                    {faq_body[0].primary.section_title}
                  </h3>
                  {faq_body[0].items.length &&
                    faq_body[0].items.map((item, idx_content) => (
                      <Accordion
                        key={`accordion-group_${1}-${idx_content}`}
                        question={item.question}
                        answer={item.answer_content.html}
                      />
                    ))}
                </div>
              </Section>
            )}
            {is_want_more_section && (
              <WantMoreSection
                title={want_more_section_title || ''}
                links={want_more_section_links || []}
                subtitle={want_more_section_subtitle || ''}
              />
            )}
            {is_try_out_section && (
              <TryOutSection
                title={countries_title || ''}
                description={countries_subtitle1 || ''}
                countriesCount={countries_count || ''}
                countriesAndText={countries_and_in_text || ''}
                countriesLanguagesCount={countries_languages_count || ''}
                ctaText={try_out_section_cta || ''}
                toolSlug={tool_django_slug}
                closeModal={() => setCountriesModalOpen(!isCountriesModalOpen)}
              />
            )}
            <StyledModal
              isVisible={isCountriesModalOpen}
              onBackdropClick={() => setCountriesModalOpen(false)}
              onCloseClick={() => setCountriesModalOpen(false)}
            >
              <CountriesModal
                title={countries_modal_title}
                subtitle={countries_modal_subtitle}
                countries={countries_text}
                languages={languages_text}
                countriesList={countries_list.html}
                languagesList={languages_list.html}
              />
            </StyledModal>
          </Layout>
        );
      }}
    </PricingContext.Consumer>
  );
};

export const query = graphql`
  query productsTemplate($prismicLang: String, $toolSlug: String) {
    allPrismicCommonInformation(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          _previewable
          lang
          data {
            pricing_description
            pricing_footnote
            pricing_headline
            pricing_title
            pricing_cta_text
            pricing_per_tool_text
            pricing_starting_at_text
            pricing_monthly_text

            title
            body {
              ... on PrismicCommonInformationDataBodyPricingPlan {
                id
                primary {
                  is_featured
                  plan_cost
                  plan_period
                  plan_description
                  plan_name
                  pricing_cta
                }
                items {
                  is_available
                  plan_feature_name
                }
              }
            }
            hiw_cta_label
            hiw_description
            hiw_title
            hiw_sections {
              hiw_item_title
              hiw_item_headline
              hiw_item_description {
                html
              }
              hiw_item_cover {
                url
              }
            }
          }
        }
      }
    }

    allPrismicFaqsPage(filter: { lang: { eq: $prismicLang } }) {
      edges {
        node {
          _previewable
          id
          lang
          data {
            description {
              html
            }
            headline
            title
            body {
              ... on PrismicFaqsPageDataBodySection {
                id
                primary {
                  section_title
                }
                items {
                  question
                  answer_content {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }

    allPrismicToolDetail(
      filter: {
        lang: { eq: $prismicLang }
        data: { tool_django_slug: { eq: $toolSlug } }
      }
    ) {
      edges {
        node {
          _previewable
          data {
            title
            headline
            faq_headline
            faq_description {
              html
            }
            how_it_works_visible
            how_it_works_title
            how_it_works_description
            prod_first_how_description
            prod_first_how_title
            prod_how_description
            prod_how_first_tagline
            prod_how_title
            prod_second_how_description
            prod_second_how_tagline
            prod_second_how_title
            prod_third_how_description
            prod_third_how_tagline
            prod_third_how_title
            tool_django_slug
            description_tag
            description_title
            is_description

            description_main_text
            description_paragraphs {
              description_paragraph
            }
            is_metrics
            metrics_title
            metrics_subtitle
            metrics_pills {
              metrics_pill
            }
            sections {
              cta_label
              description
              headline1
              title1
              imagen {
                url
              }
            }
            description {
              html
            }
            left_image {
              url
            }
            right_image {
              url
            }
            is_subscribable
            pricing_title
            pricing_headline
            pricing_footnote
            pricing_description
            faq_body {
              ... on PrismicToolDetailDataFaqBodySection {
                id
                primary {
                  section_title
                }
                items {
                  question
                  answer_content {
                    html
                  }
                }
              }
            }
            body {
              ... on PrismicToolDetailDataBodyPricingPlanDefault {
                id
                primary {
                  plan_default_description
                  plan_default_includes
                  plan_default_datafee_details {
                    html
                  }
                  plan_default_datafee_details_text
                  plan_default_cta
                  plan_default_price
                  plan_default_price_data
                  plan_default_title
                  plan_default_vat_info
                  default_optional_metrics_visible
                }
                items {
                  plan_default_feature
                  is_optional_metric
                }
              }

              ... on PrismicToolDetailDataBodyPricingPlan {
                id
                primary {
                  is_featured
                  plan_cost
                  price_after_discount
                  plan_description
                  plan_name
                  plan_period
                  pricing_cta
                  cta_text
                  optional_metrics_visible
                }
                items {
                  is_available
                  plan_feature_name
                  is_optional_metric
                }
              }
            }
            is_try_out_section
            is_want_more_section
            is_questionnaire_flow_section
            is_key_benefits_section
            try_out_section_cta
            try_out_section_desc
            try_out_section_title
            want_more_section_title
            want_more_section_subtitle
            want_more_section_links {
              text
              link
            }
            key_benefits_section_group {
              group_description
              group_title
            }
            key_benefits_section_tag
            key_benefits_section_title
            questionnaire_flow_section_tag
            questionnaire_flow_section_title
            questionnaire_flow_section_slides {
              slide_description
              slide_title
            }
            countries_title
            countries_subtitle1
            countries_count
            countries_and_in_text
            countries_languages_count
            countries_modal_title
            countries_modal_subtitle
            countries_text
            languages_text
            countries_list {
              html
            }
            languages_list {
              html
            }

            is_showcase_section_visible
            showcase_title
            showcase_subtitle
            showcase_images {
              report_image {
                url
              }
            }
            body1 {
              ... on PrismicToolDetailDataBody1Video {
                primary {
                  video_title
                  other_videos_label
                  embed_link
                }
                items {
                  video_title
                  embed_link
                }
              }
            }
            is_video_section_visible

            section_title {
              text
            }
          }
          lang
        }
      }
    }
  }
`;

/* eslint-disable-next-line */
// @ts-ignore eslint-disable-line
export default withPrismicPreview(ProductsTemplate);
