import React from 'react';
import styled from 'styled-components';
import { Typography } from '@deeptrue-frontend/ui';
import {
  PricingContext,
  currencySymbols,
  getCurrencyName,
} from '../../providers/PricingProvider';
import Optional from '../../assets/icons/optional.svg';

const DiscountedPrice = styled(Typography)`
  font-size: 0.875rem;
  color: #144bb8;
  text-decoration: line-through;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

type FeatureType = {
  content: string;
  available?: boolean;
  isOptionalMetric?: boolean;
};

type PriceCardProps = {
  emphasis?: boolean;
  name: string;
  description: string;
  price?: string;
  period?: string;
  term?: string;
  features: FeatureType[];
  cta_text?: string;
  cta_url?: string;
  benefit?: string;
  footnote?: string;
  isFeatured?: boolean;
  planDiscountDescription?: string;
  planDiscountInfo?: string;
  btnCta?: () => void;
  btnCtaText?: string;
  priceAfterDiscount?: string;
  areOptionalMetricsVisible?: boolean;
};

const PriceCard = ({
  emphasis,
  name,
  description,
  price,
  period,
  features,
  cta_text,
  cta_url,
  term,
  benefit,
  footnote,
  isFeatured,
  priceAfterDiscount,
  areOptionalMetricsVisible,
  btnCta,
  btnCtaText,
}: PriceCardProps) => (
  <PricingContext.Consumer>
    {({ pricingData }) => {
      return (
        <article
          className={`pricing-card ${
            isFeatured ? 'pricing-card--is-featured' : ''
          } ${emphasis ? 'pricing-card--emphasis' : ''}`}
        >
          <div>
            <div className="pricing-card__header">
              <h4 className="pricing-card__name">{name}</h4>
              <p
                className={`pricing-card__description ${
                  emphasis ? 'pricing-card__description--emphasis' : ''
                }`}
              >
                {description}
              </p>
              {priceAfterDiscount ? (
                <>
                  <DiscountedPrice>
                    {currencySymbols[pricingData.currentCurrency]}{' '}
                    {`${Math.round(
                      parseInt(price) *
                        pricingData.currencyRates[
                          getCurrencyName(pricingData.currentCurrency)
                        ]
                    )}/${period}`}
                  </DiscountedPrice>
                  <p className="pricing-card__price">
                    <span className="pricing-card__quantity">
                      {
                        currencySymbols[
                          getCurrencyName(pricingData.currentCurrency)
                        ]
                      }{' '}
                      {Math.round(
                        parseInt(priceAfterDiscount) *
                          pricingData.currencyRates[
                            getCurrencyName(pricingData.currentCurrency)
                          ]
                      )}
                    </span>{' '}
                    / <span className="pricing-card__period">{period}</span>
                  </p>
                </>
              ) : (
                <p className="pricing-card__price">
                  <span className="pricing-card__quantity">CHF {price}</span> /{' '}
                  <span className="pricing-card__period">{period}</span>
                </p>
              )}
              {term && <p className="pricing-card__term">{term}</p>}
            </div>
            <div className="pricing-card__content">
              <p className="pricing-card__intro">Includes:</p>
              {features.length && (
                <ul className="pricing-card__features">
                  {features.map(
                    (feature: FeatureType, idx: number) =>
                      !feature.isOptionalMetric && (
                        <li
                          className={`pricing-card__feature ${
                            feature.available
                              ? 'pricing-card__feature--is-enabled'
                              : ''
                          }`}
                          key={`pricing-card__feature-${feature.content}-${idx}`}
                        >
                          <svg
                            className={`pricing-card__icon ${
                              feature.available
                                ? 'pricing-card__icon--success'
                                : ''
                            }`}
                          >
                            <use
                              xlinkHref={
                                feature.available ? '#check' : '#close'
                              }
                            />
                          </svg>
                          {feature.content}
                        </li>
                      )
                  )}
                </ul>
              )}
              {areOptionalMetricsVisible && (
                <>
                  <p className="pricing-card__intro">Optional:</p>
                  <ul className="pricing-card__features">
                    {features.map((feature: FeatureType, idx: number) => {
                      return (
                        feature.isOptionalMetric && (
                          <li
                            className={`pricing-card__feature ${
                              feature.available
                                ? 'pricing-card__feature--is-enabled'
                                : ''
                            }`}
                            key={`pricing-card__feature-${feature.content}-${idx}`}
                          >
                            <img src={Optional} />
                            {feature.content}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
            {benefit && footnote && (
              <div className="pricing-card__footer">
                <p className="pricing-card__benefit">{benefit}</p>
                <p className="pricing-card__footnote">{footnote}</p>
              </div>
            )}
          </div>
          <div>
            {cta_text && cta_url && (
              <div className="pricing-card__actions">
                <a href={cta_url} className="pricing-card__cta button">
                  {cta_text}
                </a>
              </div>
            )}

            {btnCta && btnCtaText && (
              <button className="hero__button" onClick={btnCta}>
                {btnCtaText}
              </button>
            )}
          </div>
        </article>
      );
    }}
  </PricingContext.Consumer>
);

export default PriceCard;
