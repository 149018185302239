import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Typography } from '@deeptrue-frontend/ui';

const Section = styled.section`
  height: 20rem;
  width: 100%;
  background-color: #001f33;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 0.5rem;
`;

const Title = styled(Typography)`
  font-weight: 500;
  font-size: 2rem;
  color: white;

  @media (min-width: 960px) {
    font-size: 3rem;
  }
`;
const Description = styled(Typography)`
  font-weight: 500;
  font-size: 1.25rem;
  color: white;
  line-height: 2rem;

  @media (min-width: 960px) {
    font-size: 1.5rem;
  }
`;

const StyledButton = styled.button`
  text-decoration: underline;
`;

interface Props {
  title: string;
  description: string;
  countriesCount: string;
  countriesAndText: string;
  countriesLanguagesCount: string;
  ctaText: string;
  toolSlug: string;
  closeModal: () => void;
}

const TryOutSection = ({
  title,
  description,
  countriesCount,
  countriesAndText,
  countriesLanguagesCount,
  ctaText,
  toolSlug,
  closeModal,
}: Props) => {
  return (
    <Section>
      <Title>{title}</Title>
      <Description mb="2rem">
        {description}{' '}
        <StyledButton onClick={closeModal}>{countriesCount}</StyledButton>{' '}
        {countriesAndText}{' '}
        <StyledButton onClick={closeModal}>
          {countriesLanguagesCount}
        </StyledButton>
        {'.'}
      </Description>
      <Link className="tc-section__button" to={`/brief?briefing=${toolSlug}`}>
        {ctaText}
      </Link>
    </Section>
  );
};

export default TryOutSection;
