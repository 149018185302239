import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 80%;
  @media (min-width: 601px) {
    width: 75%;
  }
`;

const ShowcaseElement = ({ image, onMouseEnter, onMouseLeave }) => {
  return (
    <StyledImage
      src={image.report_image.url}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      alt={'report image'}
    />
  );
};

export default ShowcaseElement;
