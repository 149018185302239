import React from 'react';
import { Typography } from '@deeptrue-frontend/ui';
import styled from 'styled-components';
import Section from '../Base/Section';

const Ul = styled.ul`
  list-style-type: disc;
  margin: 1rem 1.5rem;
`;

const Li = styled.li`
  color: #1656e9;
  line-height: 2rem;
  font-weight: 500;
  font-size: 1.25rem;
  cursor: pointer;
`;

interface Props {
  title: string;
  subtitle?: string;
  links: {
    link: string;
    text: string;
  }[];
}

const WantMoreSection = ({ title, subtitle, links }: Props) => {
  return (
    <Section>
      <Typography fontSize="2rem" fontWeight="medium" color="#253858">
        {title}
      </Typography>
      <Typography
        fontSize="1rem"
        fontWeight="medium"
        color="#253858"
        mt="1rem"
        mb="2rem"
      >
        {subtitle}
      </Typography>
      <Ul>
        {links.map(({ link, text }, index) => {
          return (
            <Li key={index}>
              <a href={link} target="_blank" rel="noreferrer">
                {text}
              </a>
            </Li>
          );
        })}
      </Ul>
    </Section>
  );
};

export default WantMoreSection;
