import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@deeptrue-frontend/ui';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ShowcaseElement from './ShowcaseElement';
import iconRight from '../../../assets/icons/arrow_right_white.svg';
import iconLeft from '../../../assets/icons/arrow_left_white.svg';

const Section = styled.section`
  width: 100%;
  background-color: #001f33;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 0.5rem;
  position: relative;
`;

const Title = styled(Typography)`
  margin-top: 5rem;
  font-weight: 700;
  font-size: 1rem;
  color: #759ef0;
  margin-bottom: 1rem;
`;
const Subtitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  color: #ffffff;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  @media (min-width: 601px) {
    align-items: flex-start;
    margin-left: 8.44rem;
  }
`;

const ArrowLeft = styled.span`
  position: absolute;
  cursor: pointer;
  top: 45%;
  left: 2%;
  transform: translateY(-50%);

  @media (min-width: 601px) {
    left: 5%;
  }
`;

const ArrowRight = styled.span`
  position: absolute;
  right: 2%;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
  @media (min-width: 601px) {
    right: 5%;
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 2.5rem auto;
  position: relative;
  background-color: #001f33;
  margin-bottom: 5rem;

  .react-multi-carousel-list {
    padding-bottom: 1.5rem;
  }
  .react-multi-carousel-item {
    padding: 0 20px;
  }

  .react-multi-carousel-track {
    margin: 0 auto;
  }
  .react-multi-carousel-dot-list {
    bottom: -1rem;
  }
`;

interface Props {
  title: string;
  subtitle: string;
  images: any;
}

const CustomArrows = ({ carouselState, next, previous }: any) => {
  const { currentSlide, totalItems, slidesToShow } = carouselState;

  const isPrevVisible = currentSlide !== 0;
  const isNextVisible = currentSlide + slidesToShow < totalItems;
  return (
    <>
      {isPrevVisible && (
        <ArrowLeft onClick={previous}>
          <img src={iconLeft} alt="left" />
        </ArrowLeft>
      )}
      {isNextVisible && (
        <ArrowRight onClick={next}>
          <img src={iconRight} alt="left" />
        </ArrowRight>
      )}
    </>
  );
};

const checkTouchDevice = () => {
  return 'ontouchstart' in window;
};

const ShowcaseSection = ({ title, subtitle, images }: Props) => {
  const [autoPlay, setAutoPlay] = useState(true);
  const [isTouchDevice, setTouchDevice] = useState(false);

  useEffect(() => {
    setTouchDevice(checkTouchDevice());
  }, []);

  return (
    <Section>
      <Wrapper>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Wrapper>
      <Content className="m-carousel">
        <Carousel
          showDots={true}
          arrows={false}
          infinite={true}
          autoPlay={isTouchDevice ? false : autoPlay}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          renderButtonGroupOutside
          renderDotsOutside
          customButtonGroup={<CustomArrows />}
          responsive={{
            desktop: {
              breakpoint: { max: 8000, min: 0 },
              items: 1,
            },
          }}
        >
          {images.map((image, idx) => {
            return (
              <ShowcaseElement
                image={image}
                key={idx}
                onMouseEnter={() => setAutoPlay(false)}
                onMouseLeave={() => setAutoPlay(true)}
              />
            );
          })}
        </Carousel>
      </Content>
    </Section>
  );
};

export default ShowcaseSection;
