import React, { ReactNode } from 'react';

export type StepProps = {
  name?: string;
  counter?: string;
  title?: string;
  content?: string;
  children?: ReactNode;
  className?: string;
  thirds?: boolean;
};
const Step = ({
  title,
  name,
  content,
  counter = null,
  children,
  className = null,
}: StepProps) => {
  return (
    <article className={className ? `m-step ${className}` : 'm-step'}>
      <div className="m-step__header">
        {counter && <span className="m-step__header-counter">{counter}</span>}
        <h3 className="m-step__header-title">{name}</h3>
      </div>
      <h4 className="m-step__title">{title}</h4>
      <div className="m-step__content">
        {content && content}
        {children && children}
      </div>
    </article>
  );
};

export default Step;
