import React from 'react';
import styled from 'styled-components';
import { Typography } from '@deeptrue-frontend/ui';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.875rem 0.875rem;
  background: #fbfbfb;
  border: 1px solid #eaefff;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  max-width: 16.75rem;
  height: 16.375rem;
  margin: 0 auto;
`;

interface Props {
  index: number;
  title: string;
  description: string;
}

const QuestionnaireCard = ({ index, title, description }: Props) => {
  return (
    <Card>
      <Typography color="#5C6370" fontSize="1.5rem">
        {index}
      </Typography>
      <Typography color="#5C6370" fontSize="1rem" mt="0.5rem">
        {title}
      </Typography>
      <Typography color="#253858" mt="2rem">
        {description}
      </Typography>
    </Card>
  );
};

export default QuestionnaireCard;
