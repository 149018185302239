import React from 'react';
import { Typography } from '@deeptrue-frontend/ui';
import styled from 'styled-components';

const Pill = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8effc;
  padding: 2px 16px;
  margin: 0 0.5rem 1rem;
  border-radius: 12px;
`;

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 1.5rem;
  align-items: center;
  margin-bottom: 6.5rem;
  margin-top: 6.5rem;

  @media (min-width: 960px) {
    margin-bottom: 8rem;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50rem;
`;

const PillsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SectionTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: bold;
  white-space: no-wrap;
  color: #253858;
  font-weight: 500;
  margin-bottom: 1rem;
`;

const SectionSubtitle = styled(Typography)`
  font-size: 1rem;
  color: #253858;
  font-weight: 500;
  margin-bottom: 2rem;
`;

interface PillType {
  metrics_pill: string;
}

interface Props {
  metrics_pills: PillType[];
  metrics_title: string;
  metrics_subtitle: string;
}

const KeyMetricsSection = ({
  metrics_pills,
  metrics_title,
  metrics_subtitle,
}: Props) => {
  return (
    <Section>
      <Content>
        <SectionTitle>{metrics_title}</SectionTitle>
        <SectionSubtitle>{metrics_subtitle}</SectionSubtitle>
        <PillsWrapper>
          {metrics_pills.map(({ metrics_pill }: PillType, index: number) => {
            return (
              <Pill key={index}>
                <Typography variant="copy2">{metrics_pill}</Typography>
              </Pill>
            );
          })}
        </PillsWrapper>
      </Content>
    </Section>
  );
};

export default KeyMetricsSection;
